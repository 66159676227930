import React, { Component } from 'react';
import { Form, Input, Tooltip, Select, Row, Col, Button, message } from 'antd';
import VehicleModal from './components/vehicleModal';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import './index.less';

const Option = Select.Option;
const FormItem = Form.Item;
const IconFont = Loader.loadBaseComponent('IconFont');
const Title = Loader.loadBusinessComponent('SystemTitle');
const TwoColumnLayout = Loader.loadBaseComponent('TwoColumnLayout');
const FormUpload = Loader.loadBusinessComponent('UploadComponents', 'UploadSingleFile');

@withRouter
@Decorator.businessProvider('tab', 'user')
@Decorator.withEntryLog()
@observer
class VehicleEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleImgUrl: '',
      data: {},
      villageId: '',
      villageName: '',
      personData: {},
      name: '',
      personType: false // 是否为小区人员 默认不是
    };
    this.modalRef = React.createRef();
  }

  componentWillMount() {
    const { location } = this.props;
    let id = Utils.queryFormat(location.search).id;
    LM_DB.get('parameter', id).then((res = { data: {} }) => {
      const { data = {} } = res;
      this.setState({
        name: res.data.ownerName || '',
        data: res.data,
        villageId: res.villageId, //选中的社区Id
        villageName: res.villageName,
        personType: data.residentialArea === '1' ? true : false
      });
    });
  }

  /**记录当前页面对应的上传图片的url */
  uploadDone = file => {
    this.setState({ vehicleImgUrl: file.url });
  };

  cancel = () => {
    const { tab, location } = this.props;
    tab.closeCurrentTab({ location });
  };

  save = () => {
    const { form } = this.props;
    const { personData = {}, villageId, vehicleImgUrl, personType } = this.state;
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }
      if (values.residentialArea === '1' && !personType) {
        message.error('请关联小区人员');
        return;
      }
      let option = { ...values, villageId, personId: personData.id };
      if (vehicleImgUrl) {
        option.vehicleUrl = vehicleImgUrl;
      } else {
        option.vehicleUrl = undefined;
      }
      if (values.residentialArea === '1') {
        option.personId = personData.id;
        option.ownerName = undefined;
      }
      Service.community.updateEnteringVehicles(option).then(() => {
        SocketEmitter.emit(SocketEmitter.eventName.updateCommunityVehicle, {});
        this.cancel();
      });
    });
  };

  modalShow = () => {
    this.modalRef.current.changVisible(true);
  };

  changeName = option => {
    const { data } = this.state;
    data.residentialArea = '1';
    data.ownerAddress = option.presentAddress || '';
    this.setState({ data, personData: option, name: option.name, personType: true });
  };

  selectChange = parms => {
    const { data } = this.state;
    data.residentialArea = parms;
    this.setState({ data });
  };

  changeOwn = e => {
    this.setState({ name: e.target.value });
  };

  render() {
    const { data = {}, vehicleImgUrl, villageId, villageName, personData, name } = this.state;
    let { vehicleUrl, vehiclePlateNo, vehicleCatena, vehicleBrand, vehicleColor, residentialArea, vehiclePlateColour, ownerAddress, ownerName, vehicleType } = data;
    const {
      form: { getFieldDecorator }
    } = this.props;
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 9 },
      colon: true
    };
    if (vehicleColor === '其他') {
      vehicleColor = null;
    }
    return (
      <TwoColumnLayout title={vehiclePlateNo} className="vehicle-edit-view">
        <div className="vehicle-edit-Form">
          <Form layout="vertical">
            <Title name="车辆信息" />
            <Row>
              <Col span={24}>
                <FormItem label="车辆照片：" {...formItemLayout}>
                  {getFieldDecorator('vehicleUrl', {
                    initialValue: vehicleImgUrl ? vehicleImgUrl : vehicleUrl
                  })(
                    <FormUpload
                      uploadDone={this.uploadDone}
                      uploadTip={false}
                      uploadService={(...args) => Service.community.uploadVillageImg(...args)}
                      children={
                        <div className="avatar">
                          <img src={vehicleImgUrl ? vehicleImgUrl : vehicleUrl ? vehicleUrl : ''} />
                        </div>
                      }
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem label="车牌号：" {...formItemLayout} autoComplete="off">
                  {getFieldDecorator('vehiclePlateNo', {
                    initialValue: vehiclePlateNo
                  })(<Input disabled={true} />)}
                </FormItem>

                <Form.Item label="车牌品牌：" {...formItemLayout}>
                  {getFieldDecorator('vehicleBrand', {
                    rules: [{ required: false, message: '请选择车牌品牌' }],
                    initialValue: vehicleBrand
                  })(
                    <Select placeholder="请选择车牌品牌">
                      {Dict.map.vehicleBrands.map(v => (
                        <Option value={v.value}>{v.label}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <FormItem label="车系：" {...formItemLayout} autoComplete="off">
                  {getFieldDecorator('vehicleCatena', {
                    rules: [{ required: false, message: '车系必须填写' }],
                    initialValue: vehicleCatena
                  })(<Input placeholder="请填写车系" />)}
                </FormItem>

                <FormItem label="车型：" {...formItemLayout} autoComplete="off">
                  {getFieldDecorator('vehicleType', {
                    rules: [{ required: false, message: '车型必须填写' }],
                    initialValue: vehicleType
                  })(<Input placeholder="请填写车型" />)}
                </FormItem>
              </Col>
              <Col span={24}>
                <Form.Item label="车辆颜色：" {...formItemLayout}>
                  {getFieldDecorator('vehicleColor', {
                    rules: [{ required: false, message: '请选择车辆颜色' }],
                    initialValue: vehicleColor ? +vehicleColor : undefined
                  })(
                    <Select placeholder="请选择车辆颜色">
                      {Dict.map.vehicleColor.map(v => (
                        <Option value={v.value}>{v.text}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label="车牌颜色：" {...formItemLayout}>
                  {getFieldDecorator('vehiclePlateColour', {
                    rules: [{ required: false, message: '请选择车牌颜色' }],
                    initialValue: vehiclePlateColour ? +vehiclePlateColour : undefined
                  })(
                    <Select placeholder="请选择车牌颜色">
                      {Dict.map.plateColor.map(v => (
                        <Option value={v.value}>{v.text}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Title name="车主信息" />
              <Col span={24}>
                <Form.Item label="是否小区居民：" {...formItemLayout}>
                  {getFieldDecorator('residentialArea', {
                    initialValue: residentialArea
                  })(
                    <Select onChange={this.selectChange}>
                      <Option value={'1'}>是</Option>
                      <Option value={'0'}>否</Option>
                    </Select>
                  )}
                </Form.Item>

                <FormItem label="姓名：" {...formItemLayout} autoComplete="off">
                  {getFieldDecorator('ownerName', {
                    initialValue: personData.name ? personData.name : ownerName
                  })(
                    <div className="chose-people">
                      <Input disabled={residentialArea === '1' ? true : false} value={name} onChange={this.changeOwn} />
                      {residentialArea === '1' && (
                        <Tooltip title="关联人员">
                          <IconFont className="chose-icon" type="icon-S_Bar_Linked" onClick={this.modalShow} />
                        </Tooltip>
                      )}
                    </div>
                  )}
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem label="住址：" {...formItemLayout} autoComplete="off">
                  {getFieldDecorator('ownerAddress', {
                    initialValue: ownerAddress
                    // })(<Input disabled={ residentialArea === '1' ? true : false} />)}
                  })(<Input disabled={true} />)}
                </FormItem>
              </Col>
            </Row>
          </Form>
          <div className="vehicle-edit-Form-footer">
            <Button onClick={this.cancel}>取消</Button>
            <Button type="primary" onClick={this.save}>
              保存
            </Button>
          </div>
          <VehicleModal villageId={villageId} villageName={villageName} data={data} ref={this.modalRef} changeName={this.changeName} />
        </div>
      </TwoColumnLayout>
    );
  }
}

export default Form.create()(VehicleEdit);
