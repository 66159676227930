import React, { Component } from 'react';
import { Spin } from 'antd';
import './index.less';

const ModalComponent = Loader.loadBaseComponent('ModalComponent');
const ImageView = Loader.loadBaseComponent('ImageView');
const SearchInput = Loader.loadBaseComponent('SearchInput');
const NoData = Loader.loadBaseComponent('NoData');
const Loading = Loader.Loading;
const CaptureCard = Loader.loadBaseComponent('Card', 'CaptureCard');
const InfiniteScrollLayout = Loader.loadBaseComponent("ReactVirtualized", 'InfiniteScrollLayout');

class VehicleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      list: [],
      visible: false,
      hasLoadMore: false,
      active: {},
      searchData: {
        offset: 0,
        limit: 24,
        villageIds: [this.props.villageId],
        keywords: '',
        peroidType: 0,
        registeredPeopleType: -1,
        sort: ['recent_time|desc']
      }
    };
    this.infinitRef = React.createRef();
  }
  componentWillMount() {
    this.queryList();
  }
  
  componentWillReceiveProps(nextProps) {
    if(nextProps.villageId !== this.props.villageId) {
      this.mergeSearchData({villageIds: [nextProps.villageId]});
    }
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   const { villageId } = nextProps;
  //   const { searchData } = prevState;
  //   if(villageId !== searchData.villageId) {
  //     searchData.villageId = villageId;
  //     this.queryList(searchData);
  //     return { searchData }
  //   }
  //   return null;
  // }

  mergeSearchData = async (parms = {}, search = true, loadMore = false) => {
    if (search) {
      const list = await this.queryList(parms, loadMore);
    }
  };

  queryList = async (parms = {}, loadMore = false) => {
    await this.setState({ loading: true, hasLoadMore: false });
    try {
      let { searchData, list, hasLoadMore } = this.state;
      const option = Object.assign({}, searchData, parms);
      const listRes = await Service.community.queryRegisteredPeopleWithoutSensitiveData(option);
      let reslist = listRes.list || [];
      if (reslist.length === searchData.limit) {
        hasLoadMore = true;
      }
      if (loadMore) {
        reslist = list.concat(reslist);
      }
      this.setState({
        searchData: option,
        total: listRes.total || 0,
        list: reslist,
        loading: false,
        hasLoadMore
      });
      return reslist;
    }
    catch (err) {
      this.setState({
        list: [],
        loading: false,
        hasLoadMore: false
      });
    }
  }

  loadMore = () => {
    const {searchData} = this.state;
    const parms = { offset: searchData.offset += searchData.limit };
    this.mergeSearchData(parms, true, true);
  };

  inputChange = value => {
    const parms = { keywords: value, offset: 0 };
    this.mergeSearchData(parms, true, false);
  };

  clickCard = async(parms) => {
    await this.setState({ active: parms });
    this.infinitRef && this.infinitRef.current && this.infinitRef.current.forceUpdateGrid();
  };

  onOk = () => {
    const { changeName } = this.props;
    const { active } = this.state;
    changeName && changeName(active);
    this.changVisible(false);
  };

  cancel = () => {
    this.changVisible(false);
  };

  changVisible = parms => {
    this.setState({ visible: parms });
  };

  render() {
    const { loading, visible, list = [], hasLoadMore, active } = this.state;
    const { data = {}, villageName } = this.props;
    const { vehicleUrl, vehiclePlateNo, vehicleBrand, vehicleType, vehicleColor } = data;
    const plate = Dict.map.vehicleColor.find(v => v.value == vehicleColor);
    return (
      <ModalComponent visible={visible} forceRender={true} title="关联人员" className="vehicle-modal-view" width="900px" onCancel={this.cancel} onOk={this.onOk}>
        <div className="modal-header">
          <div className="header-line">
            <span className="line-title">车辆照片：</span>
            <ImageView className="line-img" src={vehicleUrl} hasErrorImageStyle={false} />
          </div>
          <div className="header-line">
            <span className="line-title">车辆车牌：</span>
            {vehiclePlateNo}
          </div>
          <div className="header-line">
            <span className="line-title">车辆信息：</span>
            {vehicleBrand ? Dict.getLabel('vehicleBrands', vehicleBrand) : ''}
          </div>
          <div className="header-line">
            <span className="line-title">车辆型号：</span>
            {vehicleType}
          </div>
          <div className="header-line">
            <span className="line-title">车辆颜色：</span>
            {plate ? plate.text : ''}
          </div>
        </div>
        <div className="modal-content">
          <header className="title">
            <span className="title-text">{villageName}</span>
            <SearchInput placeholder="请输入你要搜索的人员" onChange={this.inputChange} />
          </header>
          <section className="content-list">
            <Spin spinning={loading}>
              {list.length ? (
                <InfiniteScrollLayout
                  itemWidth={178}
                  itemHeight={274}
                  data={list}
                  rowCount={4}
                  hasLoadMore={hasLoadMore}
                  ref={this.infinitRef}
                  loadMore={this.loadMore}
                  renderItem={item => (
                    <CaptureCard
                      className={`${active.id === item.id ? 'active' : ''}`}
                      onClick={() => this.clickCard(item)}
                      imgUrl={item.portraitPicUrl}
                      deviceName={item.recentAddress}
                      name={item.name}
                    />
                  )}
                />
              ) : loading ? (
                <Loading />
              ) : (
                <NoData />
              )}
            </Spin>
          </section>
        </div>
      </ModalComponent>
    );
  }
}

export default VehicleModal;
